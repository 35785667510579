// simple TFunction for getting countable or dynamic translations
import get from "lodash.get";

export function getTranslate(resources: Record<string, any>) {
  return (key: string, options?: Record<string, any> | number) => {
    const value = get(resources, key);

    if (!value) {
      return key;
    }

    if (!options) {
      return value;
    }

    if (typeof options === "number") {
      return value.split(" | ")[options - 1];
    }

    const regex = new RegExp("{(" + Object.keys(options).join("|") + ")}", "g");

    return value.replace(regex, (m, $1) => options[$1] || m);
  };
}
