import { useCallback } from "react";
import { isSSRToggledCheck } from "./ssrHelpers";
import { processEnvServer } from "hooks/useSsrHooks";
import { getGlobal } from "utils-globals/getGlobal";
import yn from "yn";

export type BuildPathOptions = {
  [key: string]: string | null | undefined;
};

export default (url: string, options: BuildPathOptions = {}) => {
  const uri = new URL(url, "http://www.example.com"); // base is required when url is relative

  const searchParams = uri.searchParams;
  Object.keys(options).forEach(key => {
    if (!!options[key] && !searchParams.has(key)) {
      searchParams.set(key, options[key] as string);
    }
  });

  return uri.pathname + uri.search + uri.hash;
};

const PG_PATH = "/catalog/category/products.jsp";
const CG_PATH = "/catalog/category/collections.jsp";
const RHRPG_PATH = "/search/";
const PDP_PATH = "/catalog/product/product.jsp";
const GALLERY_PATH = "/store-locations";
const CW_PATH = "/custom-windows";

export const isForcedReloadPath = function (to) {
  const env = getGlobal("_env");
  const FEATURE_APP_REFRESH_POLLING = yn(env?.FEATURE_APP_REFRESH_POLLING);
  const FEATURE_FORCE_RELOAD_PATHS = isSSRToggledCheck();

  const appRefreshRequired =
    FEATURE_APP_REFRESH_POLLING && !processEnvServer
      ? localStorage.getItem("appRefreshRequired")
      : "";

  const getResult = useCallback(
    (path?): boolean => {
      if (appRefreshRequired === "yes") {
        return true;
      }
      if (!FEATURE_FORCE_RELOAD_PATHS) {
        return false;
      }
      const value = path || to;
      if (typeof value === "string") {
        return (
          // value?.includes(PG_PATH) ||
          value?.includes(CG_PATH) ||
          value?.includes(RHRPG_PATH) ||
          // value?.includes(PDP_PATH) ||
          value?.includes(CW_PATH) ||
          value?.includes(GALLERY_PATH)
        );
      }
      return (
        // value?.pathname?.includes(PG_PATH) ||
        value?.pathname?.includes(CG_PATH) ||
        value?.pathname?.includes(RHRPG_PATH) ||
        // value?.pathname?.includes(PDP_PATH) ||
        value?.pathname?.includes(CW_PATH) ||
        value?.includes(GALLERY_PATH)
      );
    },
    [appRefreshRequired, FEATURE_FORCE_RELOAD_PATHS, to]
  );

  try {
    let result = getResult();

    return { forcedReloadPath: result, getResult, error: null };
  } catch (error) {
    console.error("error using isForcedReloadPath function");
    return { forcedReloadPath: false, getResult: () => false, error };
  }
};

export const searchParams = (basePath?: string) => {
  const params = {} as any;
  basePath?.split("&").map(item => {
    const [key, value] = item.split("=");
    return (params[key] = value);
  });
  return params;
};
