import React, { FC } from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import { Grid, Theme, useMediaQuery } from "utils/material-ui-core";
import { ImageSkeleton } from "skeleton-image";
import { IMAGE_ASPECT_RATIO } from "utils/constants";

export interface RelatedProductCardSkeletonProps {
  "data-testid"?: string;
}

export const RelatedProductCardSkeleton: FC<
  RelatedProductCardSkeletonProps
> = props => {
  const mdUp = useMediaQuery<Theme>(theme => theme.breakpoints.up("md"));
  return (
    <Grid
      container
      data-testid={props["data-testid"]}
      spacing={1}
      className="py-0 px-0 md:px-[90px]"
    >
      <Grid item xs={5} md={2}>
        <ImageSkeleton
          aspectRatio={IMAGE_ASPECT_RATIO["thumbnail"]}
          animation="pulse"
          variant="rect"
          isDarkBg={true}
          dataTestid="related-product-card-image-skeleton"
        />
      </Grid>
      <Grid item xs={7} md={4}>
        <Skeleton
          className={"w-full !h-[22px] !transform-none"}
          data-testid="related-product-card-text-skeleton"
        />
      </Grid>
    </Grid>
  );
};

export default RelatedProductCardSkeleton;
