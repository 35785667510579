import React from "react";
import { AppEnv } from "utils-app-env";
import { isToggleOn } from "./isToggleOn";

export interface ToggleTernary<
  OnOutcome = any,
  OffOutcome = any,
  Outcome = any
> {
  (on: OnOutcome | null | undefined, off: OffOutcome | null | undefined):
    | (Outcome extends OnOutcome ? OnOutcome : OffOutcome)
    | null
    | undefined;
  readonly isOn: boolean;
  readonly isOff: boolean;
}

export interface ToggleTernaryFactory<Outcome = any> {
  (feature: keyof AppEnv): ToggleTernary<Outcome>;
}

export const ternary: ToggleTernaryFactory = feature => {
  const checkOn = () => isToggleOn(feature);

  const checkOff = () => !isToggleOn(feature);

  const result: ToggleTernary = Object.assign(
    (on, off) => {
      if (isToggleOn(feature)) {
        return on;
      } else {
        return off;
      }
    },
    {
      get isOn() {
        return checkOn();
      },
      get isOff() {
        return checkOff();
      }
    }
  );

  Object.defineProperty(result, "isOn", { get: checkOn });

  Object.defineProperty(result, "isOff", { get: checkOff });

  return result;
};

export interface ToggleProps {
  children?: any;
  feature: keyof AppEnv;
  off?: (enabled?: boolean) => any;
  on?: (enabled?: boolean) => any;
  forceOn?: () => boolean;
}

export const Toggle: React.FC<ToggleProps> = ({
  children,
  feature,
  on,
  off,
  forceOn
}) => {
  if (isToggleOn(feature) || forceOn?.()) {
    if (on) {
      return on(true);
    } else {
      return children;
    }
  } else {
    if (off) {
      return off(false);
    } else {
      return null;
    }
  }
};

Toggle.defaultProps = {};

export default Toggle;
