import React, { FC } from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import {
  Container,
  List,
  ListItem,
  Theme,
  makeStyles,
  createStyles
} from "utils/material-ui-core";
import CollectionCardSkeleton, {
  CollectionCardSkeletonProps
} from "skeleton-collection-card";
import ImageSkeleton from "skeleton-image";
import { IMAGE_ASPECT_RATIO } from "utils/constants";
import classNames from "classnames";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    promoContainer: {
      [theme.breakpoints.down("md")]: {
        paddingLeft: 0,
        paddingRight: 0
      }
    },
    cgListItem: {
      paddingTop: 15,
      paddingBottom: 15,
      [theme.breakpoints.up("sm")]: {
        paddingTop: 12,
        paddingBottom: 12
      },
      [theme.breakpoints.up("md")]: {
        paddingTop: 20,
        paddingBottom: 20
      },
      [theme.breakpoints.up("xl")]: {
        paddingTop: 25,
        paddingBottom: 25
      }
    }
  })
);

export const CollectionGallerySkeleton: FC<CollectionCardSkeletonProps> = ({
  animation
}) => {
  const classes = useStyles();
  return (
    <>
      <div
        className={` mb-[20px]`}
        data-testid={`collectionGallerySkeleton-test`}
      >
        <div>
          <Skeleton
            width="75%"
            height={100}
            style={{ display: "block", margin: "0 auto" }}
            animation={animation}
          />
        </div>
        <div className={`mt-[20px]`}>
          <div className={classNames([classes.promoContainer, `md:px-[40px]`])}>
            <ImageSkeleton
              aspectRatio={IMAGE_ASPECT_RATIO["collectionBanner"]}
              animation={animation}
            />
          </div>
        </div>
      </div>

      <Container>
        <List data-testid={`collectionGallery-loading`}>
          {Array.from(new Array(2)).map((item, index) => (
            <ListItem key={index} disableGutters className={classes.cgListItem}>
              <CollectionCardSkeleton animation={animation} />
            </ListItem>
          ))}
        </List>
      </Container>
    </>
  );
};

export default CollectionGallerySkeleton;
