import React, { FC, RefObject } from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import { Card, CardContent, CardMedia } from "utils/material-ui-core";
import { IMAGE_ASPECT_RATIO } from "utils/constants";
import { ImageSkeleton } from "skeleton-image";
import classNames from "classnames";

export interface RHRProductCardSkeleton {
  disableProductInfoSkeleton?: boolean;
  animation?: "pulse" | "wave" | false;
}

export const RHRProductCardSkeleton: FC<RHRProductCardSkeleton> = ({
  disableProductInfoSkeleton,
  animation
}) => {
  return (
    <Card elevation={0} square>
      <CardMedia>
        <ImageSkeleton
          aspectRatio={IMAGE_ASPECT_RATIO["verticalProductTile"]}
          animation={animation}
        />
      </CardMedia>
      {!disableProductInfoSkeleton && (
        <CardContent style={{ paddingLeft: 0 }}>
          <Skeleton animation={animation} />
          <Skeleton width="30%" animation={animation} />
          <Skeleton width="60%" animation={animation} />
        </CardContent>
      )}
    </Card>
  );
};

export interface RHRProductListSkeletonProps {
  columns: any;
  numItems: number;
  hasBanner?: boolean;
  disableProductInfoSkeleton?: boolean;
  animation?: "pulse" | "wave" | false;
  styleProps?: React.CSSProperties;
  skeletonRef?: RefObject<HTMLDivElement> | null;
}

export const RHRProductListSkeleton: FC<RHRProductListSkeletonProps> = ({
  hasBanner,
  columns,
  numItems,
  disableProductInfoSkeleton,
  animation,
  styleProps = {},
  skeletonRef
}) => {
  return (
    <>
      <div
        style={styleProps}
        ref={skeletonRef}
        data-testid={`productGallery-loading`}
      >
        {columns > 0 ? (
          <div
            className={classNames(
              `grid gap-x-4 sm:gap-x-8 md:gap-x-10 gap-y-8 sm:gap-y-9 md:gap-y-12 lg:gap-y-[60px] mb-8 md:mb-9 lg:mb-[60px] !items-baseline`,
              {
                "grid-cols-3": 12 / columns === 3,
                "grid-cols-2": 12 / columns === 2,
                "grid-cols-1": 12 / columns === 1
              }
            )}
          >
            {Array.from(new Array(numItems)).map((item, index) => (
              <RHRProductCardSkeleton
                key={`${index}`}
                disableProductInfoSkeleton={disableProductInfoSkeleton}
                animation={animation}
              />
            ))}
          </div>
        ) : null}
      </div>
    </>
  );
};

export default RHRProductListSkeleton;
