import React from "react";
import Keycloak, {
  KeycloakLoginOptions,
  KeycloakLogoutOptions,
  KeycloakPromise,
  KeycloakRegisterOptions
} from "keycloak-js";
import { getGlobal } from "utils-globals/getGlobal";
import {
  KeycloakProvider,
  useKeycloak as useKeycloakWeb
} from "@react-keycloak/web";
import {
  Cookies,
  SSRKeycloakProvider,
  useKeycloak as useKeycloakSSR
} from "@react-keycloak/ssr";
import { KeycloakInitOptions } from "keycloak-js";
import { processEnvServer } from "hooks/useSsrHooks";
import getCountryFromUrl, { getLanguageFromUrl } from "utils/getCountryFromUrl";
import { countries } from "resources/countries-config.json";
import yn from "yn";

const { location } = window;

const env = getGlobal("_env");

// To remove infuriating redirects during local development
const CAN_REDIRECT = env.ENV_ID !== "LOCAL" || !env.DISABLE_KEYCLOAK_REDIRECTS;

const FEATURE_INTERNATIONAL = yn(env.FEATURE_INTERNATIONAL);

const specialKeyCloakOptions = [
  {
    url: "/contract-sales",
    requireLogin: true,
    clientID: env.REACT_APP_KEYCLOAK_CONTRACT_CLIENTID
  },
  {
    url: "/trade-sales",
    requireLogin: true,
    clientID: env.REACT_APP_KEYCLOAK_TRADE_CLIENTID
  },
  {
    url: "/gift-registry/create-a-registry.jsp",
    requireLogin: true
  },
  {
    url: "/gift-registry/manage-gift-lists.jsp",
    requireLogin: true
  },
  {
    url: "/my-account",
    requireLogin: true
  }
];

const getSpecialKeyCloakOption = () =>
  specialKeyCloakOptions.find(option => location.pathname.includes(option.url));

export const useKeycloak = (): { keycloak: Keycloak } => {
  return (processEnvServer ? useKeycloakSSR() : useKeycloakWeb()) as {
    keycloak: Keycloak;
  };
};

export interface KeycloakProviderAdapterProps {
  cookiePersistor?: any;
  children?: any;
}

export const kCFetchClientId = () => {
  return (
    getSpecialKeyCloakOption()?.clientID ||
    localStorage.getItem("REACT_APP_KEYCLOAK_CLIENTID") ||
    env.REACT_APP_KEYCLOAK_CLIENTID
  );
};

export const keycloak: Keycloak =
  typeof window !== "undefined" && !processEnvServer
    ? new Keycloak({
        url: `${env.REACT_APP_KEYCLOAK_ORIGIN}${env.REACT_APP_KEYCLOAK_PATH}`,
        realm: `${env.REACT_APP_KEYCLOAK_REALM}`,
        clientId: processEnvServer
          ? env.REACT_APP_KEYCLOAK_CLIENTID
          : kCFetchClientId()
      })
    : ("" as unknown as Keycloak);

/**
 * Due to keycloak-js use of location.replace(), the current entry in the
 * browser history is removed so that users can't navigate back to the current
 * page. The keycloak adapter policy requires all five of the following methods
 * to be overridden in the adapter (kind of all-or-nothing). the first four are
 * redirects (side-effects) and so return nothing, whereas the fifth,
 * `redirectUri`, always returns a URL string.
 */

export const KeycloakAdapter: Maybe<Keycloak.KeycloakAdapter> =
  typeof window !== "undefined" && !processEnvServer
    ? {
        login: (options: KeycloakLoginOptions) =>
          new Promise((_, reject) => {
            try {
              const country = getCountryFromUrl();
              const language = getLanguageFromUrl();

              const loginURL = new URL(keycloak.createLoginUrl(options));
              if (FEATURE_INTERNATIONAL) {
                loginURL?.searchParams?.append(
                  "region",
                  countries?.[country]?.regionSF
                );
                loginURL?.searchParams?.append("country", country);
                loginURL?.searchParams?.append("language", language?.mapped);
                const locale =
                  loginURL?.searchParams?.get("ui_locales") ||
                  language?.mapped?.split("-")?.[0];
                loginURL?.searchParams?.set("ui_locales", locale);
              }

              if (CAN_REDIRECT) {
                return (location.href = loginURL?.toString());
              }
            } catch (e) {
              console.log("keycloak adapter error", e);
              reject(e);
            }
          }) as unknown as KeycloakPromise<void, void>,

        logout: (options: KeycloakLogoutOptions) => {
          /** Force /my-account logout to always redirect to home */
          if (window.location.pathname.includes("/my-account")) {
            const url = new URL(window.location.href);
            const prefix = url.pathname.split("/").slice(0, 3).join("/");
            url.pathname = prefix;
            location.href = keycloak.createLogoutUrl({
              redirectUri: url.href
            });
          } else if (CAN_REDIRECT) {
            location.href = keycloak.createLogoutUrl(options);
          }
          return new Promise(() => {}) as unknown as KeycloakPromise<
            void,
            void
          >;
        },

        register: (options: KeycloakRegisterOptions) => {
          const country = getCountryFromUrl();
          const language = getLanguageFromUrl();

          const registryURL = new URL(keycloak.createRegisterUrl(options));
          if (FEATURE_INTERNATIONAL) {
            registryURL?.searchParams?.append(
              "region",
              countries?.[country]?.regionSF
            );
            registryURL?.searchParams?.append("country", country);
            registryURL?.searchParams?.append("language", language?.mapped);
            const locale =
              registryURL?.searchParams?.get("ui_locales") ||
              language?.mapped?.split("-")?.[0];
            registryURL?.searchParams?.set("ui_locales", locale);
          }

          if (CAN_REDIRECT) {
            location.href = registryURL?.toString();
          }
          return new Promise(() => {}) as unknown as KeycloakPromise<
            void,
            void
          >;
        },

        accountManagement: () => {
          const accountUrl = keycloak.createAccountUrl();
          if (typeof accountUrl === "string") {
            if (CAN_REDIRECT) {
              location.href = accountUrl;
            }
          } else {
            throw new Error("Not supported by the OIDC server");
          }
          return new Promise(() => {}) as unknown as KeycloakPromise<
            void,
            void
          >;
        },
        redirectUri: (
          options: Parameters<Keycloak.KeycloakAdapter["redirectUri"]>[0]
        ) => {
          /*
           * encodeHash is never actually used - see this section of keycloak.js:
           * https://github.com/keycloak/keycloak/blob/master/adapters/oidc/js/src/main/resources/keycloak.js#L1314
           */
          if (CAN_REDIRECT) {
            if (options && typeof options.redirectUri === "string") {
              return options.redirectUri;
            } else if (
              keycloak.redirectUri &&
              typeof keycloak.redirectUri === "string"
            ) {
              return keycloak.redirectUri;
            } else {
              return location.href;
            }
          } else {
            return location.href;
          }
        }
      }
    : undefined;

// debugger;
export const KeycloakProviderAdapter: React.FC<
  KeycloakProviderAdapterProps
> = ({ cookiePersistor, children }) => {
  if (processEnvServer) {
    const KC_INIT_OPTIONS = {
      url: `${env.REACT_APP_KEYCLOAK_ORIGIN}${env.REACT_APP_KEYCLOAK_PATH}`,
      realm: `${env.REACT_APP_KEYCLOAK_REALM}`,
      clientId: env.REACT_APP_KEYCLOAK_CLIENTID,
      checkLoginIframe: false
    };
    return (
      <SSRKeycloakProvider
        keycloakConfig={KC_INIT_OPTIONS}
        persistor={processEnvServer ? cookiePersistor : new Cookies()}
      >
        {children}
      </SSRKeycloakProvider>
    );
  } else {
    /*
     * Due to keycloak-js's use of window.location.replace(), the current entry
     * in the browser history is removed so users can't navigate back to the
     * current page using the Back button.
     *
     * Here we initialize Keycloak with the KcAdapter methods in order to correct
     * that behavior.
     *
     * onLoad and promiseType are set to the default used by KeycloakProvider
     * when initializing
     *
     * silentCheckSsoRedirectUri has been added to prevent URL rewrite when changing
     * routes or refreshing the page (see keycloak Javascript Adapter documentation
     * https://bit.ly/2ZpEwCp)
     */
    // removed promiseType: "native",
    //here `window` is available, so `window.document` (or simply `document`) is available too
    const requireLoginOnLoad =
      getSpecialKeyCloakOption()?.requireLogin ?? false;

    const KC_INIT_OPTIONS: KeycloakInitOptions = {
      adapter: KeycloakAdapter,
      onLoad: requireLoginOnLoad ? "login-required" : "check-sso",
      silentCheckSsoRedirectUri: !requireLoginOnLoad
        ? `${location.origin}/silent-check-sso.html`
        : undefined,
      checkLoginIframe: false,
      enableLogging: process.env.NODE_ENV === "development"
    };

    keycloak.clientId = kCFetchClientId();

    return (
      <KeycloakProvider keycloak={keycloak} initConfig={KC_INIT_OPTIONS}>
        {children}
      </KeycloakProvider>
    );
  }
};
