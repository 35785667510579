import React, { FC } from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import { Grid, CardContent } from "utils/material-ui-core";
import { ImageSkeleton } from "skeleton-image";
import { IMAGE_ASPECT_RATIO } from "utils/constants";

export interface StockContentSkeletonProps {
  "data-testid"?: string;
}

export const StockContentSkeleton: FC<StockContentSkeletonProps> = props => {
  return (
    <Grid container data-testid={props["data-testid"]} spacing={1}>
      <Grid item xs={5} md={2}>
        <ImageSkeleton
          aspectRatio={IMAGE_ASPECT_RATIO["thumbnail"]}
          animation="pulse"
          variant="rect"
          isDarkBg={true}
        />
      </Grid>
      <Grid item xs={4} md={4}>
        <CardContent>
          <Skeleton />
          <Skeleton width="60%" />
          <br />
          <Skeleton />
          <Skeleton />
          <br />
        </CardContent>
      </Grid>
    </Grid>
  );
};

export default StockContentSkeleton;
