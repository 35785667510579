import { checkCountryIsEu } from "./checkCountryIsEu";

type Options = {
  origin?: string;
  countryLanguagePrefix?: string;
};

function checkLastSlash(value: string) {
  if (value?.length === 0) return "";

  if (value.charAt(value.length - 1) === "/") {
    return value;
  } else {
    return `${value}/`;
  }
}

export function getReturnPolicyLink(country: string, options?: Options) {
  const isEU = checkCountryIsEu({ country });
  const routePrefix = isEU ? "customer-experience" : "customer-service";
  const postfix = isEU ? "" : ".jsp";

  const origin = checkLastSlash(options?.origin || "");
  const countryLanguagePrefix = checkLastSlash(
    options?.countryLanguagePrefix || ""
  );

  const optionsPrefix = `${origin}${countryLanguagePrefix}`;
  const normalizedOptionsPrefix = optionsPrefix ? optionsPrefix : "/";
  return `${normalizedOptionsPrefix}${routePrefix}/return-policy${postfix}`;
}
