import React, { FC } from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import { Card } from "utils/material-ui-core";
import { ImageSkeleton } from "skeleton-image";
import { IMAGE_ASPECT_RATIO } from "utils/constants";

export interface CollectionCardSkeletonProps {
  animation?: "pulse" | "wave" | false;
}

export const CollectionCardSkeleton: FC<CollectionCardSkeletonProps> = ({
  animation
}) => {
  return (
    <Card
      elevation={0}
      square
      className={"m-auto max-w-[1150px]"}
      data-testid={`collection-card-skeleton`}
    >
      <div className={`flex flex-row-reverse`}>
        <div className={`w-full`}>
          <ImageSkeleton
            aspectRatio={IMAGE_ASPECT_RATIO["collectionBanner"]}
            animation={animation}
          />
        </div>
        <div className={`w-[50%]`}>
          <div className={`p-4`}>
            <Skeleton width="60%" animation={animation} />
            <Skeleton width="40%" animation={animation} />
            <br />
            <Skeleton width="60%" animation={animation} />
          </div>
        </div>
      </div>
    </Card>
  );
};

export default CollectionCardSkeleton;
