import { countryCurrencyMapper } from "resources/countries-config.json";
import { getGlobal } from "utils-globals/getGlobal";
import { defaultCountryCodeFromPostalCode } from "utils/addressUtils";
import yn from "yn";

const env = getGlobal("_env");
const FEATURE_INTERNATIONAL = yn(env.FEATURE_INTERNATIONAL);

type CurrencyCode = {
  country: string;
  postalCode: Maybe<string>;
  userType: Maybe<string>;
};

const getCurrencyCode = ({ country, postalCode, userType }: CurrencyCode) => {
  if (FEATURE_INTERNATIONAL) {
    return countryCurrencyMapper[country];
  } else {
    return postalCode && userType !== "CONTRACT"
      ? defaultCountryCodeFromPostalCode(postalCode) === "CA"
        ? "CAD"
        : "USA" // Trust me; ATG wants this to be USA not USD
      : "USA";
  }
};

export default getCurrencyCode;
