import SplunkRum from "@splunk/otel-web";
import SplunkSessionRecorder from "@splunk/otel-web-session-recorder";
import { getGlobal } from "utils/Globals/getGlobal";

/**
 * # Splunk RUM
 * - [Documentation](https://docs.splunk.com/observability/en/gdi/get-data-in/rum/browser/configure-rum-browser-instrumentation.html)
 * - [Package](https://www.npmjs.com/package/@splunk/otel-web)
 */
export function splunkRumInit() {
  const env = getGlobal("_env");
  SplunkRum.init({
    realm: env.SPLUNK_REALM,
    rumAccessToken: env.SPLUNK_RUM_TOKEN,
    applicationName: env.SPLUNK_APPLICATION_NAME,
    deploymentEnvironment: env.ENV_ID
  });
}

/**
 * # Splunk Session Recorder
 * - [Documentation](https://docs.splunk.com/observability/en/gdi/get-data-in/rum/browser/configure-rum-browser-instrumentation.html)
 * - [Package](https://www.npmjs.com/package/@splunk/otel-web-session-recorder)
 */
export function splunkSessionRecorderInit() {
  const env = getGlobal("_env");
  SplunkSessionRecorder.init({
    realm: env.SPLUNK_REALM,
    rumAccessToken: env.SPLUNK_RUM_TOKEN
  });
}
