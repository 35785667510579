export const hasPDPWarning = (item: string) => {
  const dataKeyRegex = /data-key="([^"]+)"/;
  const dataGroupRegex = /data-group="([^"]+)"/;

  const hasDataKey = dataKeyRegex.test(item);
  const hasDataGroup = dataGroupRegex.test(item);

  if (hasDataKey && hasDataGroup) {
    const dataKeyMatch = dataKeyRegex.exec(item);
    const dataKey = dataKeyMatch ? dataKeyMatch[1] : null;
    const dataGroupMatch = dataGroupRegex.exec(item);
    const dataGroup = dataGroupMatch ? dataGroupMatch[1] : null;

    return { hasWarning: true, dataKey, dataGroup };
  } else {
    return { hasWarning: false };
  }
};
