import { intersection, union, omit, omitBy, isNil } from "lodash";
import {
  EXCLUDED_PRODUCT_CONFIGURATOR_OPTION_DETAILS,
  EXCLUDED_PRODUCT_CONFIGURATOR_OPTION_SWATCH_DETAILS
} from "@RHCommerceDev/utils/constants";

export const checkIsSwatchId = optId => {
  return optId?.includes("_") && !optId?.toLowerCase().includes("m_");
};

export const getFormattedOptionIds = options => {
  return Object.values(options).flatMap((opt: string) =>
    opt?.includes("_") && !opt?.toLowerCase().includes("m_")
      ? opt?.split("_")
      : opt
  );
};

/**
 * getSelectedOptionType
 * @param availableOptions
 * @param id
 *
 * Method to get option type (ex: item, color, fabric, etc) by option id from availableOptions
 */
export const getSelectedOptionType = ({ availableOptions, id, swatchData }) => {
  const isSwatchGroup = swatchData?.swatchGroups?.find(swatchGroup => {
    const isSwatchGroupList = swatchGroup?.swatchGroupList?.find(
      swatchGroupListElement => {
        return swatchGroupListElement?.swatches
          ?.map(swatch => swatch?.swatchId)
          ?.includes(id);
      }
    );
    return isSwatchGroupList;
  });
  if (isSwatchGroup) {
    return isSwatchGroup?.swatchGroupMaterialType;
  }

  const isFinishSwatchGroup = swatchData?.finishSwatchGroups?.find(
    finishSwatchGroup => {
      return finishSwatchGroup?.swatches
        ?.map(swatch => swatch?.swatchId)
        ?.includes(id);
    }
  );

  if (isFinishSwatchGroup) {
    return "finishSwatchGroups";
  }

  return availableOptions?.find(availableOption => {
    return availableOption?.options?.filter(o => o?.id === id)?.length;
  })?.type;
};

/**
 * getUpdatedSelectedOption
 * @param availableOptions
 * @param ids
 *
 * Method to create a mapper object which has type as key and id as value
 * example:
 * updatedSelectedOptions = {
 *   Depth: 73000029,
 *   Size: 34900039
 * }
 */
export const getUpdatedSelectedOption = ({
  availableOptions,
  ids,
  swatchData
}) => {
  const updatedSelectedOptions = {};

  ids?.forEach(id => {
    const type = getSelectedOptionType({ availableOptions, id, swatchData });
    if (type) {
      updatedSelectedOptions[type] = id;
    }
  });

  return updatedSelectedOptions;
};

export const getSwatchOptionData = (selectedSwatches: { [key: string]: any }) =>
  selectedSwatches &&
  Object.values(selectedSwatches)?.reduce((prev, next) => {
    let obj: any = { ...prev };
    next?.options?.map((opt: any) => {
      obj[opt?.optionType] = opt?.id;
    });
    return obj;
  }, {});

export const getSelectedOptionsFromAvailableOptions = (
  availableOptions: ProductAvailableOptionSet[]
) => {
  let lineItemPreselected: { [key: string]: any } = {};

  if (availableOptions) {
    availableOptions?.map((option: ProductAvailableOptionSet) => {
      const options = option?.options?.filter(o => o?.status !== "unavailable");
      if (options?.length === 1) {
        const childOption = options?.[0];
        lineItemPreselected[childOption?.type] = childOption?.id;
      }
      options?.map((childOption: ProductAvailableOption) => {
        if (childOption?.status === "selected") {
          lineItemPreselected[childOption?.type] = childOption?.id;
        }
      });
    });
  }

  return lineItemPreselected;
};

export const getSelectedOptions = (
  availableOptions: ProductAvailableOptionSet[],
  swatches: any,
  getDiffOptions: boolean = false,
  forcedOption: { [key: string]: string } = {},
  isMultiSku: boolean = false
) => {
  let lineItemPreselected: { [key: string]: any } =
    getSelectedOptionsFromAvailableOptions(availableOptions);

  const selectedOptions: string[] = Object.values({
    ...lineItemPreselected,
    ...getSwatchOptionData(swatches),
    ...forcedOption
  });

  // remove id's from selected optionIds which are not part of availableOptions
  const filteredOptions: any[] = [];
  selectedOptions?.forEach(selectedOption => {
    availableOptions?.forEach(option => {
      const matchedOption = option?.options
        ?.filter(o => o?.status !== "unavailable")
        ?.find(o => selectedOption === o?.id);
      if (matchedOption) {
        filteredOptions?.push(selectedOption);
      }
    });
  });

  if (getDiffOptions) {
    return selectedOptions?.filter(x => !filteredOptions?.includes(x));
  }
  if (isMultiSku) {
    return selectedOptions;
  }

  return filteredOptions;
};

export const getOptionFromSwatch = swatch => {
  const options = {};
  swatch?.options?.forEach(option => {
    options[option?.optionType] = option?.id;
  });
  return options;
};

export const getAllOptionTypes = (productConfiguration, isAddon = false) => {
  const filterOptions = isAddon
    ? productConfiguration?.optionDetails
    : filterProductConfigurationOptionDetails(productConfiguration)
        ?.optionDetails;

  const optionTypes = [...(filterOptions?.map(option => option?.type) || [])];

  if (
    productConfiguration?.swatchData?.finishSwatchGroups?.length &&
    !isAddon
  ) {
    productConfiguration?.swatchData?.finishSwatchGroups?.forEach(
      finishSwatchGroup => {
        optionTypes.push(finishSwatchGroup?.groupMaterial);
      }
    );
  }
  if (productConfiguration?.swatchData?.swatchGroups?.length && !isAddon) {
    productConfiguration?.swatchData?.swatchGroups?.forEach(swatchGroup => {
      optionTypes.push(swatchGroup?.swatchGroupMaterial);
    });
  }
  return optionTypes;
};

export const getSelectedOptionLength = chosenLineItemOptions => {
  return (
    chosenLineItemOptions &&
    Object.values(chosenLineItemOptions)?.filter(
      chosenLineItemOption => chosenLineItemOption?.toString()?.length
    )?.length
  );
};

export const getPreSelectedOptionFromConfiguration = (
  productConfiguration,
  isInstock = false
) => {
  if (productConfiguration?.preselectedOptions?.length) {
    return productConfiguration?.preselectedOptions;
  }
  if (
    productConfiguration?.default_instock_preselected_options?.length &&
    isInstock
  ) {
    return productConfiguration?.default_instock_preselected_options;
  }
  if (productConfiguration?.default_preselected_options?.length) {
    return productConfiguration?.default_preselected_options;
  }
  return [];
};

export const getPreSelectedOptions = (productConfiguration, isInstock) => {
  const preselectedOptionIds = getPreSelectedOptionFromConfiguration(
    productConfiguration,
    isInstock
  );
  const preselectedOptions: any = {};

  preselectedOptionIds?.forEach(preselectedOptionId => {
    const type = getSelectedOptionType({
      availableOptions: productConfiguration?.optionDetails,
      id: preselectedOptionId,
      swatchData: productConfiguration?.swatchData
    });

    if (
      type
      // &&
      // !EXCLUDED_PRODUCT_CONFIGURATOR_OPTION_DETAILS?.includes(
      //   type?.toString()?.toLowerCase()
      // )
    ) {
      preselectedOptions[type] = preselectedOptionId;
    }
  });

  return { preselectedOptions, preselectedOptionIds };
};

export const filterInstockOptionDetail = ({
  availableOptions,
  configuratorOptionMatrix,
  isInstock,
  defaultOptions
}) => {
  if (!isInstock) {
    return defaultOptions;
  }
  const inStockOptions = Object.keys(configuratorOptionMatrix);

  return availableOptions?.map(availableOption => ({
    ...availableOption,
    options: availableOption?.options?.filter(option =>
      inStockOptions?.includes(option?.id)
    )
  }));
};

export const filterOptionDetails = optionDetails => {
  return optionDetails?.filter(optionDetail => {
    return optionDetail?.swatchGroupMaterialType?.toString()?.toLowerCase()
      ? !EXCLUDED_PRODUCT_CONFIGURATOR_OPTION_DETAILS?.includes(
          optionDetail?.swatchGroupMaterialType?.toString()?.toLowerCase()
        )
      : !EXCLUDED_PRODUCT_CONFIGURATOR_OPTION_DETAILS?.includes(
          optionDetail?.type?.toString()?.toLowerCase()
        );
  });
};

export const filterProductConfigurationOptionDetails = (
  productConfiguration,
  isAddOn = false
) => {
  return {
    ...productConfiguration,
    optionDetails: isAddOn
      ? productConfiguration?.optionDetails
      : filterOptionDetails(productConfiguration?.optionDetails)
  };
};

export const initializeSwatchGroups = swatchGroups => {
  return swatchGroups?.map(swatchGroup => ({
    ...swatchGroup,
    swatches: swatchGroup?.swatches?.map(swatch => ({
      ...swatch,
      status: "available"
    }))
  }));
};

export const initializeSwatchData = swatchData => {
  return {
    ...swatchData,
    swatchGroups: initializeSwatchGroups(swatchData?.swatchGroups),
    finishSwatchGroups: initializeSwatchGroups(swatchData?.finishSwatchGroups)
  };
};

/**
 * filterAvailableOptions
 *
 * @param availableOptions
 * @param productConfiguration
 * @param selectedOptions
 * @param isInstock
 *
 * Method to get latest avaialble options after filtering all options by latest selected option ids
 */
export const createProductLineItemAvailableOptions = ({
  productConfiguration,
  availableOptions,
  selectedOptions,
  isInstock,
  isAddon
}) => {
  if (!productConfiguration) {
    return;
  }

  const { instockOptionMatrix, optionMatrix, swatchData } =
    productConfiguration;

  const configuratorOptionMatrix = isInstock
    ? instockOptionMatrix
    : optionMatrix;

  const optionTypes = getAllOptionTypes(productConfiguration, isAddon);

  const selectedOptionIds = Object.values(selectedOptions);

  availableOptions = filterInstockOptionDetail({
    availableOptions,
    configuratorOptionMatrix,
    isInstock,
    defaultOptions: productConfiguration?.optionDetails
  });

  if (!selectedOptionIds?.length) {
    return {
      availableOptions,
      swatchData: initializeSwatchData(swatchData)
    };
  }

  const lastSelectedOption: any = selectedOptionIds?.length
    ? selectedOptionIds?.[selectedOptionIds?.length - 1]
    : "";

  const updatedSelectedOptionId = Object.values(selectedOptions);

  let selectedList: string[] = [];

  selectedList =
    lastSelectedOption in configuratorOptionMatrix
      ? configuratorOptionMatrix[lastSelectedOption]
      : [];

  let selectedOptionMatrix: any = {};
  let finalOptionIds = [];

  if (updatedSelectedOptionId?.length > 1) {
    optionTypes?.forEach(type => {
      Object.entries(selectedOptions)?.forEach(
        ([updatedSelectedOptionType, id]: [string, string]) => {
          const arr =
            updatedSelectedOptionType !== type && id in configuratorOptionMatrix
              ? configuratorOptionMatrix[id]
              : [];
          if (selectedOptionMatrix[type]?.length && arr?.length) {
            selectedOptionMatrix[type] = intersection(
              selectedOptionMatrix[type],
              arr
            );
          } else if (!selectedOptionMatrix?.[type]?.length) {
            selectedOptionMatrix[type] = arr;
          }
        }
      );
      finalOptionIds = union(finalOptionIds, selectedOptionMatrix?.[type]);
    });
  } else {
    finalOptionIds =
      lastSelectedOption in configuratorOptionMatrix
        ? configuratorOptionMatrix[lastSelectedOption]
        : [];

    if (
      !finalOptionIds?.length &&
      optionTypes?.length === 1 &&
      configuratorOptionMatrix
    ) {
      finalOptionIds = Object.keys(configuratorOptionMatrix)?.length
        ? Object.keys(configuratorOptionMatrix)
        : [];
    }
  }

  selectedList = [
    ...finalOptionIds,
    ...(updatedSelectedOptionId as any[]),
    ...selectedList
  ];

  const filtered = availableOptions?.map(option => {
    return {
      ...option,
      options: option?.options
        ?.map(optionItem => {
          if (updatedSelectedOptionId?.length > 1) {
            return {
              ...optionItem,
              status: selectedOptionMatrix?.[optionItem?.name]?.includes(
                optionItem?.id
              )
                ? "available"
                : "unavailable"
            };
          } else {
            return {
              ...optionItem,
              status: selectedList?.includes(optionItem?.id)
                ? "available"
                : "unavailable"
            };
          }
        })
        ?.map((optionItem, index) => {
          return {
            ...optionItem,
            status:
              updatedSelectedOptionId?.includes(optionItem?.id) ||
              availableOptions?.[index]?.options?.length === 1
                ? "selected"
                : optionItem?.status
          };
        })
    };
  });

  const filteredSwatchGroups =
    updatedSelectedOptionId?.length > 1
      ? swatchData?.swatchGroups?.map(swatchGroup => {
          // const isSameSwatchGroup = swatchGroup?.swatches?.find(
          //   swatch => selectedOptions?.swatchGroups === swatch?.swatchId
          // );

          // if (isSameSwatchGroup && updatedSelectedOptionId?.length === 1) {
          //   return {
          //     ...swatchGroup,
          //     swatches: swatchData?.swatchGroups
          //   };
          // }

          if (updatedSelectedOptionId?.length) {
            return {
              ...swatchGroup,
              swatches: swatchGroup?.swatches
                ?.map(swatch => {
                  return {
                    ...swatch,
                    status: selectedOptionMatrix?.swatchGroups?.includes(
                      swatch?.swatchId
                    )
                      ? "available"
                      : "unavailable"
                  };
                })
                ?.map(swatch => ({
                  ...swatch,
                  status: updatedSelectedOptionId?.includes(swatch?.swatchId)
                    ? "selected"
                    : swatch?.status || "available"
                }))
            };
          }
        })
      : swatchData?.swatchGroups?.map(swatchGroup => {
          return {
            ...swatchGroup,
            swatches: swatchGroup?.swatches?.map(swatch => ({
              ...swatch,
              status: updatedSelectedOptionId?.includes(swatch?.swatchId)
                ? "selected"
                : swatch?.status || "available"
            }))
          };
        });

  const filteredFinishSwatchGroups =
    updatedSelectedOptionId?.length > 1
      ? swatchData?.finishSwatchGroups?.map(finishSwatchGroup => {
          // const isSameSwatchGroup = finishSwatchGroup?.swatches?.find(
          //   swatch => selectedOptions?.finishSwatchGroups === swatch?.swatchId
          // );

          // if (isSameSwatchGroup && updatedSelectedOptionId?.length === 1) {
          //   return {
          //     ...finishSwatchGroup,
          //     swatches: swatchData?.finishSwatchGroups
          //   };
          // }

          if (updatedSelectedOptionId?.length) {
            return {
              ...finishSwatchGroup,
              swatches: finishSwatchGroup?.swatches
                ?.map(swatch => {
                  return {
                    ...swatch,
                    status: selectedOptionMatrix?.finishSwatchGroups?.includes(
                      swatch?.swatchId
                    )
                      ? "available"
                      : "unavailable"
                  };
                })
                ?.map(swatch => ({
                  ...swatch,
                  status: updatedSelectedOptionId?.includes(swatch?.swatchId)
                    ? "selected"
                    : swatch?.status || "available"
                }))
            };
          }
        })
      : swatchData?.finishSwatchGroups?.map(swatchGroup => {
          return {
            ...swatchGroup,
            swatches: swatchGroup?.swatches?.map(swatch => ({
              ...swatch,
              status: updatedSelectedOptionId?.includes(swatch?.swatchId)
                ? "selected"
                : swatch?.status || "available"
            }))
          };
        });

  return {
    availableOptions: filtered,
    swatchData: {
      ...swatchData,
      swatchGroups: filteredSwatchGroups,
      finishSwatchGroups: filteredFinishSwatchGroups
    }
  };
};

export const updateOptionDataStatus = ({ swatchData, swatchStatus }) => {
  const updatedOptionStatus = {
    ...swatchData,
    swatchGroups: swatchData?.swatchGroups?.map(swatchGroup => {
      return {
        ...swatchGroup,
        swatches: swatchGroup?.swatches?.map(swatch => {
          return {
            ...swatch,
            status:
              swatch?.swatchId in swatchStatus
                ? swatchStatus[swatch?.swatchId]?.status
                    ?.toString()
                    ?.toLowerCase()
                : "unavailable"
          };
        })
      };
    }),
    finishSwatchGroups: swatchData?.finishSwatchGroups?.map(swatchGroup => {
      return {
        ...swatchGroup,
        swatches: swatchGroup?.swatches?.map(swatch => {
          return {
            ...swatch,
            status:
              swatch?.swatchId in swatchStatus
                ? swatchStatus[swatch?.swatchId]?.status
                    ?.toString()
                    ?.toLowerCase()
                : "unavailable"
          };
        })
      };
    })
  };

  return updatedOptionStatus;
};

export const updateSwatchDataStatus = ({ swatchData, optionStatus }) => {
  const selectedSwatchOptions: any = {};

  const finishSwatchDataType =
    swatchData?.finishSwatchGroups?.[0]?.groupMaterial;

  const finishSwatchStatus = optionStatus?.allOptions?.find(
    opt =>
      opt?.type?.toString()?.toLowerCase() ===
        finishSwatchDataType?.toString()?.toLowerCase() ||
      opt?.type?.toString()?.toLowerCase() === "finish"
  )?.options;

  const swatchStatus = optionStatus?.allOptions
    ?.filter(
      opt =>
        EXCLUDED_PRODUCT_CONFIGURATOR_OPTION_SWATCH_DETAILS?.includes(
          opt?.type?.toLowerCase()
        ) || opt?.optionTypeId?.toString()?.toLowerCase() === "swatch"
    )
    ?.reduce((prevObject, currentObject) => {
      return {
        ...prevObject,
        ...currentObject?.options
      };
    }, {});

  const updatedSwatchStatus = {
    ...swatchData,
    swatchGroups: swatchData?.swatchGroups?.map(swatchGroup => {
      return {
        ...swatchGroup,
        swatchGroupList: swatchGroup?.swatchGroupList?.map(
          swatchGroupListElement => {
            return {
              ...swatchGroupListElement,
              swatches: swatchGroupListElement?.swatches?.map(swatch => {
                if (
                  swatchStatus?.[swatch?.swatchId]
                    ?.toString()
                    ?.toLowerCase() === "selected"
                ) {
                  selectedSwatchOptions[
                    swatchGroupListElement?.groupMaterialType
                  ] = swatch?.swatchId;
                }
                return {
                  ...swatch,
                  status:
                    swatch?.swatchId in swatchStatus
                      ? swatchStatus?.[swatch?.swatchId]
                          ?.toString()
                          ?.toLowerCase()
                      : "unavailable",
                  swatchFamilyPrices:
                    swatch?.swatchId in optionStatus?.swatchFamilyPrices
                      ? optionStatus?.swatchFamilyPrices[swatch?.swatchId]
                      : {}
                };
              })
            };
          }
        )
      };
    }),
    finishSwatchGroups: swatchData?.finishSwatchGroups?.map(swatchGroup => {
      return {
        ...swatchGroup,
        swatches: swatchGroup?.swatches?.map(swatch => {
          if (
            finishSwatchStatus?.[swatch?.swatchId]
              ?.toString()
              ?.toLowerCase() === "selected"
          ) {
            selectedSwatchOptions["finishSwatchGroups"] = swatch?.swatchId;
          }

          return {
            ...swatch,
            status:
              swatch?.swatchId in finishSwatchStatus
                ? finishSwatchStatus?.[swatch?.swatchId]
                    ?.toString()
                    ?.toLowerCase()
                : "unavailable",
            swatchFamilyPrices:
              swatch?.swatchId in optionStatus?.swatchFamilyPrices
                ? optionStatus?.swatchFamilyPrices[swatch?.swatchId]
                : {}
          };
        })
      };
    })
  };

  return { updatedSwatchStatus, selectedSwatchOptions };
};

export const mapOptionStatusToAvailableOptions = ({
  optionStatus,
  availableOptions
}) => {
  const selectedAvailableOptions: any = {};

  const updatedAvailableOptions = availableOptions?.map(availableOption => {
    const selectedOptionStatus = optionStatus?.allOptions?.find(
      opt => opt?.type === availableOption?.type
    );

    return {
      ...availableOption,
      options: availableOption?.options?.map(option => {
        if (
          selectedOptionStatus?.options?.[option?.id]
            ?.toString()
            ?.toLowerCase() === "selected"
        ) {
          selectedAvailableOptions[availableOption?.type] = option?.id;
        }

        return {
          ...option,
          status:
            option?.id in (selectedOptionStatus?.options || {})
              ? selectedOptionStatus?.options?.[option?.id]
                  ?.toString()
                  ?.toLowerCase()
              : "unavailable"
        };
      })
    };
  });

  return { updatedAvailableOptions, selectedAvailableOptions };
};

export const mapOptionStatusToDefaultAvailableOptions = ({
  preselectedOptions,
  availableOptions,
  swatchData
}) => {
  if (!preselectedOptions?.length) {
    return availableOptions;
  }

  const updatedAvailableOptions = availableOptions?.map(availableOption => {
    return {
      ...availableOption,
      options: availableOption?.options?.map(option => {
        if (option?.id in preselectedOptions) {
          return {
            ...option,
            status: preselectedOptions?.includes(option?.id)
              ? "selected"
              : "available"
          };
        } else {
          return {
            ...option,
            status: preselectedOptions?.includes(option?.id)
              ? "selected"
              : "available"
          };
        }
      })
    };
  });

  const updatedSwatchData = {
    ...swatchData,
    swatchGroups: swatchData?.swatchGroups?.map(swatchGroup => {
      return {
        ...swatchGroup,
        swatches: swatchGroup?.swatches?.map(swatch => {
          return {
            ...swatch,
            status: preselectedOptions?.includes(swatch?.swatchId)
              ? "selected"
              : "available"
          };
        })
      };
    }),
    finishSwatchGroups: swatchData?.finishSwatchGroups?.map(swatchGroup => {
      return {
        ...swatchGroup,
        swatches: swatchGroup?.swatches?.map(swatch => {
          return {
            ...swatch,
            status: preselectedOptions?.includes(swatch?.swatchId)
              ? "selected"
              : "available"
          };
        })
      };
    })
  };
  return { updatedAvailableOptions, updatedSwatchData };
};

export const getFetchOptionStatusRequestPayload = ({
  chosenLineItemOptions,
  inStockOnly,
  chosenLineItemUnavailableOptions
}) => {
  const keys = Object.keys(omitBy(chosenLineItemUnavailableOptions, isNil));
  const selectedOptionIds = omit(chosenLineItemOptions, keys);
  return {
    selectedOptionIds: Object.values(selectedOptionIds),
    ...(Object.values(chosenLineItemUnavailableOptions)?.length
      ? {
          unavailableOption: Object.values(
            chosenLineItemUnavailableOptions
          )?.filter(item => item)
        }
      : {}),
    ...(inStockOnly ? { inStockOnly } : {})
  };
};

export const getInstockOptionIdsFromOptionStatus = (optionStatus: any) => {
  return optionStatus?.reduce(
    (ids: any[], option: any) => [
      ...ids,
      ...(Object.keys(option?.options) || [])
    ],
    []
  );
};

export const getPriceFromSwatchFamilyPrice = (
  swatches: any,
  key = "listPrices"
) => {
  return swatches?.map((swatch: any) => {
    return swatch?.swatchFamilyPrices?.[key]?.[0];
  });
};
