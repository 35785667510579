import { PG_SEARCH_TERM } from "utils/constants";

export const addPgTermFallback = (url: string, ssr: boolean = false) => {
  // this is only happening on the client, is a user interaction thats why i can use the new URL with base

  const urlData = new URL(url, window.location.href);
  const hasCategoryId = urlData.searchParams.has("categoryId");
  const hasPgTerm = urlData.searchParams.has(PG_SEARCH_TERM);

  if (
    url?.startsWith("/") &&
    urlData.pathname === "/catalog/category/products.jsp" &&
    !hasPgTerm &&
    hasCategoryId
  ) {
    urlData.searchParams.set(
      PG_SEARCH_TERM,
      `category:${urlData.searchParams.get("categoryId")}`
    );
    return urlData.toString()?.replace(window?.location?.origin, "");
  } else {
    return url;
  }
};
