import { useEnv } from "hooks/useEnv";
const env = useEnv();
// ENABLE BELOW NAV PROMOS FOR CERTAIN UNIQUE PROMO IDS
export interface PromotionBannerIds {
  [key: string]: boolean;
}

const FREE_SHIPPING_BANNER_RH_ID = "1229031",
  FREE_SHIPPING_BANNER_BC = "rhbc497001",
  FREE_SHIPPING_BANNER_TN = "rhbc497002";

export const DISABLED_PROMOTION_BANNER_IDS: PromotionBannerIds = {
  [FREE_SHIPPING_BANNER_RH_ID]: true,
  [FREE_SHIPPING_BANNER_BC]: true,
  [FREE_SHIPPING_BANNER_TN]: true
};

export const bannerIsDisabled = (id: string) =>
  !!DISABLED_PROMOTION_BANNER_IDS?.[id];

export const shouldShowBelowNavPromo = (collection: CollectionCategory) => {
  if (env.FEATURE_BELOW_NAV_BANNER && !collection) return false;

  return !!(
    DISABLED_PROMOTION_BANNER_IDS[
      collection?.promotionDisplayItems?.custombanner?.id!
    ] ||
    DISABLED_PROMOTION_BANNER_IDS[
      collection?.promotionDisplayItems?.eventbanner?.id!
    ]
  );
};
