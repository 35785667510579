import { UK } from "./constants";

import { europeanCountries } from "resources/countries-config.json";

type CheckCountryIsEu = {
  includeCountries?: string[];
  country: string;
};

export const checkCountryIsEu = ({
  includeCountries = [UK],
  country
}: CheckCountryIsEu) => {
  return [...europeanCountries, ...includeCountries].includes(
    country?.toUpperCase()
  );
};
