import React, { FC, memo } from "react";

// import { makeStyles, createStyles, Theme } from "utils/material-ui-core";

// import { BREAKPOINT_MD, BREAKPOINT_SM } from "./constants";

interface ReadMoreProps {
  content: string;
  maxLength: number;
}

const ReadMore: FC<ReadMoreProps> = ({ content, maxLength }) => {
  return (
    <>
      <div>
        {content?.length > maxLength ? (
          <>
            <div>
              <span
                dangerouslySetInnerHTML={{
                  __html: content
                }}
              />
            </div>
          </>
        ) : (
          <div dangerouslySetInnerHTML={{ __html: content }} />
        )}
      </div>
    </>
  );
};

export default memo(ReadMore);
