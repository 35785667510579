import React from "react";

interface EnhancedTextProps {
  href?: string;
  alt?: string;
}

export interface EnhancedTextType {
  type: string;
  displayText?: string;
  props?: EnhancedTextProps;
}

const buildReactNodesFromArray = (
  messages: EnhancedTextType[]
): React.ReactNode => {
  return messages.map((current, idx) => {
    switch (current.type) {
      case "a":
        const props = current.props as React.DetailedHTMLProps<
          React.AnchorHTMLAttributes<HTMLAnchorElement>,
          HTMLAnchorElement
        >;
        return (
          <a key={idx} {...props}>
            {current.displayText}
          </a>
        );

      case "b":
      case "strong":
        return <b key={idx}>{current.displayText}</b>;

      case "br":
        return <br key={idx} />;

      case "p":
        return <p key={idx}>{current.displayText}</p>;

      case "text":
      default:
        return <React.Fragment key={idx}>{current.displayText}</React.Fragment>;
    }
  });
};

export default buildReactNodesFromArray;
