import React, { FC } from "react";
import { Helmet } from "react-helmet";
import useBrand from "hooks-use-brand/useBrand";

export interface StructuredDataProps {
  product: Product;
}

export const StructuredData: FC<StructuredDataProps> = ({ product }) => {
  const brandCode = useBrand();

  const structuredData = {
    "@context": "http://schema.org/",
    "@type": "Product",
    name: product.displayName,
    description: product.longDescription,
    productId: product.id,
    image: [product.imageUrl],
    brand: {
      "@type": "Brand",
      name: brandCode
    },
    offers: [
      {
        "@type": "AggregateOffer",
        highPrice: product.priceRangeDisplay.listPrices?.[0],
        lowPrice: product.priceRangeDisplay.listPrices?.[1],
        priceCurrency: product.priceRangeDisplay.currencySymbol
      }
    ]
  };
  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(structuredData)}
      </script>
    </Helmet>
  );
};

StructuredData.defaultProps = {};

export default StructuredData;
