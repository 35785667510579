import Skeleton from "@material-ui/lab/Skeleton";
import React, { FC } from "react";

export interface ImageSkeletonProps {
  aspectRatio: number | undefined;
  className?: string;
  variant?: "text" | "rect" | "circle";
  animation?: "pulse" | "wave" | false;
  isDarkBg?: boolean;
  color?: string;
  height?: string;
  dataTestid?: string;
  style?: React.CSSProperties;
}

export const ImageSkeleton: FC<ImageSkeletonProps> = ({
  aspectRatio,
  className,
  variant = "rect",
  animation,
  isDarkBg,
  color,
  height,
  dataTestid
}) => {
  return (
    <Skeleton
      data-testid={dataTestid}
      variant={variant}
      className={className}
      animation={animation || false}
      style={{
        width: "100%",
        height: height ?? "100%",
        backgroundColor:
          color ?? (isDarkBg ? undefined : "rgba(0, 0, 0, 0.04)"),
        // @ts-ignore
        "--aspect-ratio": aspectRatio
      }}
    />
  );
};

export default ImageSkeleton;
