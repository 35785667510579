import React, { FC } from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import { Grid } from "utils/material-ui-core";
import { ImageSkeleton } from "skeleton-image";
import { IMAGE_ASPECT_RATIO } from "utils/constants";

export const ProductOverlaySkeleton: FC = () => {
  return (
    <Grid container spacing={5}>
      <Grid item xs={6}>
        <ImageSkeleton aspectRatio={IMAGE_ASPECT_RATIO["productCard"]} />
      </Grid>
      <Grid item xs={6}>
        <Skeleton width="60%" />
        <br />
        <Skeleton width="30%" />
        <Skeleton width="30%" />
        <Skeleton width="30%" />
      </Grid>
    </Grid>
  );
};

export default ProductOverlaySkeleton;
